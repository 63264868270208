import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { RoleEnum } from '../models';

@Directive({
  selector: '[appPermissionDisabled]',
})
export class PermissionDisabledDirective {
  @Input()
  set appPermissionDisabled(permissions: string[]) {
    const userPermissions: string[] = this.store.selectSnapshot(({ app }) => app.currentUser?.permissions);
    const userRoles = this.store.selectSnapshot(({ app }) => app.currentUser?.roles);
    let access: boolean = permissions.some((per: string) => userPermissions?.includes(per));
    if (permissions.includes('user.create')) {
      access = userPermissions.some((per: string) => per.includes('user.create'));
    }
    if (access || (userRoles && userRoles[0]?.title === RoleEnum.SuperAdmin) || (userRoles && userRoles[0]?.title === RoleEnum.Employee)) {
      this.renderer.removeAttribute(this.elRef.nativeElement, 'permission-disabled');
    } else {
      this.renderer.setAttribute(this.elRef.nativeElement, 'permission-disabled', 'true');
    }
  }

  constructor(
    private store: Store,
    private elRef: ElementRef,
    private renderer: Renderer2,
  ) {}
}
