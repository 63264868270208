import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { RoleEnum } from '../models';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective {
  @Input()
  set appPermission(permission: string[]) {
    const role: string = this.store.selectSnapshot(({ app }) => app.currentUser?.roles[0]?.title);
    const permissions: string[] = this.store.selectSnapshot(({ app }) => app.currentUser?.permissions);
    let access = permission.some(per => permissions?.includes(per));
    if (permission[0] === 'user.create') {
      access = permissions.some(per => per?.includes('user.create'));
    }
    if (access || role === RoleEnum.SuperAdmin || role === RoleEnum.Employee) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  constructor(
    private store: Store,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}
}
