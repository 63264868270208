import { Directive, EventEmitter, HostBinding, HostListener, Input, Output, OnInit } from '@angular/core';

@Directive({
  selector: '[appDropFile]',
  standalone: true,
})
export class DropFileDirective implements OnInit {
  @Input() dragOverBgColor: string;
  @Input() defaultBgColor: string;

  @Output() fileDropped: EventEmitter<any> = new EventEmitter();

  @HostBinding('style.background-color') private background;
  @HostBinding('style.opacity') private opacity = '1';

  @HostListener('dragover', ['$event']) onDragOver(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.setHoverBackground();
    this.opacity = '0.8';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.resetBackground();
    this.opacity = '1';
  }

  @HostListener('drop', ['$event'])
  public ondrop(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.resetBackground();
    this.opacity = '1';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }

  ngOnInit(): void {
    this.background = this.defaultBgColor || '';
  }

  private setHoverBackground(): void {
    if (this.dragOverBgColor) {
      this.background = this.dragOverBgColor;
    }
  }

  private resetBackground(): void {
    this.background = this.defaultBgColor || '';
  }
}
