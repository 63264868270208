import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleEnum } from '../models';
import { Store } from '@ngxs/store';

@Directive({
  selector: '[appPermissionGroup]',
})
export class PermissionGroupDirective {
  constructor(
    private store: Store,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  @Input()
  set appPermissionGroup(permission: string[]) {
    const role: string = this.store.selectSnapshot(({ app }) => app.currentUser?.roles[0]?.title);
    const permissions: string[] = this.store.selectSnapshot(({ app }) => app.currentUser?.permissions);
    const access = permission.every(per => permissions.includes(per));
    if (access || role === RoleEnum.SuperAdmin) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
