import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subArray',
})
export class SubArrayPipe implements PipeTransform {
  transform(value: any[], subArrayCount: number): any {
    if (value && subArrayCount && subArrayCount > 0) {
      const itemsInSubArray = Math.ceil(value.length / subArrayCount);
      let newArr = [];
      for (let i = 1; i <= subArrayCount; i++) {
        newArr = [...newArr, value.slice((i - 1) * itemsInSubArray, itemsInSubArray * i)];
      }
      return newArr;
    } else {
      return value;
    }
  }
}
