import { Component, forwardRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgIf } from '@angular/common';
import { MaskDirective } from '../../directives/mask.directive';
import { PHONE_MASK } from '../../helpers/phone-mask';

export const EXPANDED_TEXTAREA_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaExpandedComponent),
  multi: true,
};

@Component({
  selector: 'app-textarea-expanded',
  templateUrl: './textarea-expanded.component.html',
  styleUrls: ['./textarea-expanded.component.scss'],
  providers: [EXPANDED_TEXTAREA_VALUE_ACCESSOR],
  standalone: true,
  imports: [NgIf, MaskDirective],
})
export class TextareaExpandedComponent implements ControlValueAccessor {
  @ViewChild('textareaInput', { static: true }) textareaInput;
  @ViewChild('textarea', { static: true }) textarea;

  @Input() placeholder: string;
  @Input() isNumber = false;
  @Input() block = false;
  @Input() addPhoneMask = false;
  @Input() phoneMask = PHONE_MASK;

  disabled = true;
  onChange;
  onTouched;
  value: string;

  constructor(private renderer: Renderer2) {}

  writeValue(value: any): void {
    const input = this.textareaInput.nativeElement;
    if (this.isNumber && value) {
      const valuePart = value.toString().match(/(\d{3})(\d{3})(\d{4})/);
      if (valuePart) {
        const numberView = '+' + '1-' + valuePart[1] + '-' + valuePart[2] + '-' + valuePart[3];
        this.renderer.setProperty(input, 'value', numberView);
      } else {
        this.renderer.setProperty(input, 'value', value);
      }
    } else {
      this.renderer.setProperty(input, 'value', value);
    }
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change($event): void {
    if (this.isNumber) {
      const number = $event.target.value.replace(/\D/g, '');
      this.onChange(+number);
      this.onTouched(+number);
      this.value = $event.target.value;
    } else {
      const stringValue = $event.target.value;
      this.onChange(stringValue);
      this.onTouched(stringValue);
      this.value = stringValue;
    }
  }
}
