import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ColorPickerModule } from 'ngx-color-picker';
import { MultiselectComponent } from '../dashboard/admin-dashboard/multiselect/multiselect.component';
import { RouterModule } from '@angular/router';
import { TextareaExpandedComponent } from './components/textarea-expanded/textarea-expanded.component';
import { RoleOnlyDirective } from './directives/role-only.directive';
import { SubArrayPipe } from './pipes/sub-array.pipe';
import { DisabledStateDirective } from './directives/disabled-state.directive';
import { MarkInvalidDirective } from './directives/mark-invalid.directive';
import { MapperPipe } from './pipes/mapper.pipe';
import { MaxLengthDirective } from './directives/max-length.directive';
import { RegexOnlyDirective } from './directives/regex-only.directive';
import { DropFileDirective } from './directives/drop-file.directive';
import { BindRadioDirective } from './directives/bind-radio.directive';
import { ContenteditableDirective } from './directives/contenteditable.directive';
import { ReplaceSpacesPipe } from './pipes/replace-spaces.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { TrimControlValueDirective } from './directives/trim-control-value.directive';
import { PermissionDirective } from './directives/permission.directive';
import { PermissionDisabledDirective } from './directives/permission-disabled.directive';
import { PermissionGroupDirective } from './directives/permission-group.directive';
import { TimePickerDirective } from './directives/time-picker.directive';
import { TimePipe } from './pipes/time.pipe';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FlatpickrModule.forRoot(),
    ColorPickerModule,
    RouterModule,
    DropFileDirective,
    NgScrollbarModule,
    MultiselectComponent,
    TextareaExpandedComponent,
    RoleOnlyDirective,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FlatpickrModule,
    ColorPickerModule,
    TextareaExpandedComponent,
    RoleOnlyDirective,
    SubArrayPipe,
    DisabledStateDirective,
    MarkInvalidDirective,
    ContenteditableDirective,
    MapperPipe,
    SafePipe,
    MaxLengthDirective,
    RegexOnlyDirective,
    BindRadioDirective,
    ReplaceSpacesPipe,
    PhonePipe,
    TrimControlValueDirective,
    MultiselectComponent,
    PermissionDirective,
    PermissionDisabledDirective,
    PermissionGroupDirective,
    ClickOutsideModule,
    TimePickerDirective,
    TimePipe,
  ],
  declarations: [
    PermissionDirective,
    SubArrayPipe,
    DisabledStateDirective,
    MarkInvalidDirective,
    ContenteditableDirective,
    MapperPipe,
    SafePipe,
    MaxLengthDirective,
    RegexOnlyDirective,
    BindRadioDirective,
    ReplaceSpacesPipe,
    PhonePipe,
    TrimControlValueDirective,
    PermissionDisabledDirective,
    PermissionGroupDirective,
    TimePickerDirective,
    TimePipe,
  ],
})
export class SharedModule {}
