export const incidentFormConfig = {
  caregiverClockInOut: {
    clockIn: null,
    details: null,
    wasCallsUnlink: null,
  },
  underMissedVisits: {
    underMissedVisits: null,
    details: null,
  },
  reporterInformation: {
    reporterName: null,
    relationship: null,
    contact: null,
  },
  incidentDescription: {
    hospitalizationDescription: {
      whatHappened: null,
      incidentPlace: null,
      details: null,
      dueToFall: null,
      devices: null,
      caregiverShift: null,
      caregiverPlace: null,
    },
    deathDescription: {
      whoWasPresent: null,
      whatHappened: null,
      place: null,
      caregiverPlace: null,
      diagnoses: null,
      autopsy: null,
    },
    exploitationIncidentDescription: {
      whatHappened: null,
      place: null,
      whoExploited: null,
      other: null,
      isRecurring: null,
      details: null,
    },
    fraudIncidentDescription: {
      caregiver: null,
      correctionMeasurement: null,
      explain: null,
      whatHappened: null,
    },
    serviceInterruptionIncidentDescription: {
      who: null,
      measurementProvided: null,
      details: null,
      whatHappened: null,
    },
    abuseIncidentDescription: {
      whatHappened: null,
      place: null,
      whyItHappened: null,
      lastVisit: null,
      whoAbusing: null,
      details: null,
      correctiveMeasurement: null,
      correctiveMeasurementDetails: null,
      recurringSituation: null,
      recurringSituationDetails: null,
    },
    neglectIncidentDescription: {
      whatHappened: null,
      place: null,
      whyItHappened: null,
      lastVisit: null,
      whoNeglect: null,
      details: null,
      correctiveMeasurement: null,
      correctiveMeasurementDetails: null,
      recurringSituation: null,
      recurringSituationDetails: null,
    },
    participantExposedIncidentDescription: {
      howExposed: null,
      caregiverExpose: null,
      getTested: null,
      isPositive: null,
      whenTested: null,
      resultProvided: null,
      whereTested: null,
      protectiveEquipment: null,
      anySymptoms: null,
      symptomsList: null,
    },
    caregiverExposedIncidentDescription: {
      howExposed: null,
      participantExpose: null,
      mustTest: null,
      confirmInformation: null,
      protectiveEquipment: null,
      caregiverProtectiveEquipment: null,
      details: null,
      anySymptoms: null,
      symptomsList: null,
    },
    description: null,
  },
  initialActionTaken: {
    hospitalizationInitialActionTaken: {
      caregiverInformed: null,
      details: null,
    },
    deathInitialActionTaken: {
      deleteSchedule: null,
      explain: null,
    },
    exploitationInitialActionTaken: {
      feelingSafe: null,
      restaffed: null,
      details: null,
    },
    fraudInitialActionTaken: {
      informalSupport: null,
      documentDates: null,
      contactInformation: null,
      anotherCaregiver: null,
      provideName: null,
      details: null,
    },
    serviceInterruptionInitialActionTaken: {
      informalSupport: null,
      documentDates: null,
      supportDetails: null,
      anotherCaregiver: null,
      anotherCaregiverName: null,
      anotherCaregiverDetails: null,
    },
    abuseInitialActionTaken: {
      informalSupport: null,
      documentDates: null,
      contactInformation: null,
      anotherCaregiver: null,
      provideName: null,
      details: null,
    },
    neglectInitialActionTaken: {
      informalSupport: null,
      documentDates: null,
      contactInformation: null,
      anotherCaregiver: null,
      provideName: null,
      details: null,
    },
    reportableDiseaseInitialActionTaken: {
      partiesInformed: null,
      details: null,
      servicesBeingProvided: null,
      serviceInterruption: null,
      confirmInformalSupport: null,
    },
    actionTaken: null,
  },
  was911Connected: {
    connected: null,

    fraudWas911Connected: {
      connected: null,
    },
    serviceInterruptionWas911Connected: {
      connected: null,
    },
    reportableDiseaseWas911Connected: {
      connected: null,
    },
    providerOrStaffMisconductWas911Connected: {
      connected: null,
    },
    deathWas911Connected: {
      connected: null,
    },
    exploitationWas911Connected: {
      connected: null,
      details: null,
      reportNumber: null,
    },
    abuseWas911Connected: {
      connected: null,
      details: null,
      reportNumber: null,
    },
    neglectWas911Connected: {
      connected: null,
      details: null,
      reportNumber: null,
    },
  },
  serviceCoordinator: {
    contact: null,
    name: null,
    phone: null,
    secondContact: null,
    secondName: null,
    secondPhone: null,
    dateNotifiesOfIncident: null,
    timeNotifiesOfIncident: null,
  },
  hicsIncidentId: null,
  departmentOfHealthId: null,
  bpiNumber: null,
  discoveryDate: null,
  discoveryTime: new Date(new Date().setHours(9)).setMinutes(0),
  dateOfIncident: null,
};
