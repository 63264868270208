import { Directive, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxLength]',
})
export class MaxLengthDirective {
  @Input() appMaxLength = 0;

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length > this.appMaxLength) {
      const selectionStart = input.selectionStart ?? 0;
      const selectionEnd = input.selectionEnd ?? 0;

      if (selectionStart !== selectionEnd) {
        const newValue = input.value.slice(0, selectionStart) + input.value.slice(selectionEnd, this.appMaxLength + selectionStart - selectionEnd);
        input.value = newValue.slice(0, this.appMaxLength);
      } else {
        input.value = input.value.slice(0, this.appMaxLength);
      }

      if (this.ngControl.control) {
        this.ngControl.control.patchValue(input.value);
      }
    }
  }

  constructor(@Self() private ngControl: NgControl) {}
}
