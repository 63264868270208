<div [class.disabled]="disabled" class="list">
  <div
    (click)="toggleDrop()"
    *ngIf="!isSingleselect else singleselect"
    [class.show]="openDrop"
    class="list__head">
    <ng-container *ngIf="showSelectedNames; else nameTpl">
      <ng-container *ngIf="(checkedItems$ | async)?.length === itemList.length; else listNames">
        {{ selectAllText }}
      </ng-container>
      <ng-template #listNames>
        <div class="list__names">
          <ng-container *ngIf="(checkedItems$ | async)?.length">
            <ng-container *ngFor="let item of (checkedItems$ | async); let last = last">
              <span [innerHTML]="item?.[bindLabel]"></span>
              {{ last ? '' : ', ' }}
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!(checkedItems$ | async)?.length">
            <span [innerHtml]="name"></span>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #nameTpl>{{ name }}</ng-template>
    <span *ngIf="type === 'radio' && (value || value === 0)" class="count">(1)</span>
    <ng-container
      *ngIf="isSelectAllOneIndex && isSelectAllWithSearch || (!isSelectAllOneIndex && isSelectAll && value?.length === itemList.length) else count ">
      <span *ngIf="isSelectAllOneIndex && isSelectAllWithSearch"
            class="count">(All)</span>
      <span *ngIf="!isSelectAllOneIndex && isSelectAll && value?.length === itemList.length"
            class="count">(All)</span>
    </ng-container>
    <ng-template #count>
    <span
      *ngIf="(value?.length || allItemCheckedLength)"
      class="count">
      ({{ saveAllData ? (allItemCheckedLength || 0) : (value?.length || 0) }})
    </span>
    </ng-template>
  </div>

  <ng-template #singleselect>
    <div (click)="toggleDrop()" [class.show]="openDrop" class="list__head">
      <ng-container *ngIf="checkedItems$ | async as checkedItems">
        <span [innerHTML]="checkedItems?.length
            ? checkedItems[0]?.[bindLabel]
            : selectedValue?.length
              ? selectedValue[0][bindLabel]
              : name
        "></span>
      </ng-container>
    </div>
  </ng-template>
  <div
    [ngClass]="{'show': openDrop, 'with-search': withSearchField, 'without-animation': withoutAnimation}"
    class="list__drop">
    <div *ngIf="type !== 'radio'; else radioTempalte">
      <label *ngIf="withSearchField" class="search-wrap">
        <input
          (keyup)="onSearch($event.target['value'])"
          [(ngModel)]="searchValue"
          [placeholder]="searchPlaceholder"
          class="search-select"
          type="text"/>
      </label>
      <div *ngIf="!itemList?.length && (!!searchValue || !withSearchField)" class="no-items">{{ noItemsText }}</div>

      <div *ngIf="isSelectAll || isSelectAllOneIndex && !searchValue" class="form-group">
        <input
          (change)="selectAll()"
          [checked]="isSelectAllOneIndex ? isSelectAllWithSearch : isSelectedAll && value?.length === itemList.length"
          [id]="prefix + '-select-all'"
          type="checkbox"/>
        <label [for]="prefix + '-select-all'">
          <span class="status-name strong">{{ selectAllText }}</span>
        </label>
      </div>
      <div #scrollTop class="scroll">
        <div *ngFor="let item of itemList; let i = index; trackBy: trackByFn" class="form-group">
          <input *ngIf="isSingleselect && !withSearchField" class="no-display"/>
          <input
            (change)="toggleItem(i, true)"
            [checked]="saveAllData ? item.checked : checkChecked(item[bindValue])"
            [disabled]="disableEmpty && checkChecked(item[bindValue]) || disabledItems?.includes(item[bindValue])"
            [id]="prefix ? prefix + item[bindValue] : item[bindValue]"
            type="checkbox"/>
          <label
            [attr.data-e2e]="'multiselect-' + item[bindLabel]"
            [class.hide-checkbox]="withoutCheckboxView"
            [class.radio-button-view]="radioButtonView"
            for="{{ prefix ? prefix + item[bindValue] : item[bindValue] }}">
            <span *ngIf="item.color" [style.background]="item.color" class="status-color"></span>
            <span [innerHTML]="item[bindLabel]" class="status-name"></span>
          </label>
        </div>
      </div>
    </div>

    <ng-template #radioTempalte>
      <label *ngIf="withSearchField" class="search-wrap">
        <input [placeholder]="searchPlaceholder" class="search-select" type="text"/>
      </label>
      <div *ngIf="!itemList?.length && (!!searchValue || !withSearchField)" class="no-items">{{ noItemsText }}</div>
      <input
        *ngIf="needHaveEmptyRadioButton"
        [checked]="!value && value !== 0"
        [name]="'radio-group' + number"
        id="none"
        style="display: none"
        type="radio"/>

      <div *ngFor="let item of itemList; let i = index; trackBy: trackByFn" class="radio-item select">
        <input
          (change)="toggleItem(i)"
          [checked]="item.checked"
          [id]="'changeType' + item.id + number"
          [name]="'radio-group' + number"
          type="radio"/>
        <label
          [attr.data-e2e]="'multiselect-' + item[bindLabel]"
          [class.hide-checkbox]="withoutCheckboxView"
          [class.radio-button-view]="radioButtonView"
          for="{{'changeType' + item.id + number}}">
          <span class="status-name">{{ item[bindLabel] }}</span>
        </label>
      </div>
    </ng-template>
  </div>
</div>
