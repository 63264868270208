import { Directive, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRegexOnly]',
})
export class RegexOnlyDirective {
  excludeKeyCodes: number[] = [37, 39, 46, 8];
  @Input() appMaxLength: number = null;
  private regexp: RegExp;
  private excludeKeyCodesCheck = true;

  constructor(@Self() private ngControl: NgControl) {}

  @Input() set appRegexOnly(value: string) {
    this.regexp = new RegExp(value);
  }

  @Input() set code(value: boolean) {
    this.excludeKeyCodesCheck = value;
  }

  @HostListener('keypress', ['$event'])
  onInput(event: any): void {
    if (
      !this.regexp.test(event.key) &&
      (!this.excludeKeyCodesCheck || (this.excludeKeyCodesCheck && this.excludeKeyCodes.indexOf(event.keyCode) < 0))
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = (event.clipboardData || window['clipboardData']).getData('text');
    const regex: RegExp = this.regexp;

    if (clipboardData.length > this.appMaxLength || !regex.test(clipboardData)) {
      event.preventDefault();
      return;
    }
  }
}
