import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';

@Directive({
  selector: '[appRoleOnly]',
  standalone: true,
})
export class RoleOnlyDirective {
  inView = false;

  @Input()
  set appRoleOnly(roles: string[]) {
    const currentRole: string = this.store.selectSnapshot(({ app }) => app.currentUser?.roles[0]?.title);
    if (roles.includes(currentRole) && !this.inView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.inView = true;
    } else {
      this.viewContainerRef.clear();
      this.inView = false;
    }
  }

  constructor(
    private store: Store,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}
}
