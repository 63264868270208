import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TimePipe } from '../pipes/time.pipe';

@Directive({
  selector: '[appTimePicker]',
})
export class TimePickerDirective {
  public timePipe: TimePipe = new TimePipe();
  specialKEYS = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

  @Input() maxTime: string = '11:59 PM';
  @Input() minTime: string = '12:15 AM';
  @Input() steps: number = 1;
  mask = '__:__ AM';

  constructor(
    private control: NgControl,
    private element: ElementRef,
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const transformedInput = this.timePipe.transform(value);
    this.control.control.setValue(transformedInput);
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event) {
    let value = event.target.value;
    const positionStart = this.element.nativeElement.selectionStart;
    const positionEND = this.element.nativeElement.selectionEnd;
    const positionDif = Math.abs(positionEND - positionStart);
    const position = Math.min(positionStart, positionEND);
    // Replace '_'
    const sumb = value.substring(position, position + 1);
    if (sumb === '_' && /[0-9APMapm/\s]/g.test(event.key) && !this.specialKEYS.includes(event.key)) {
      value = value.substring(0, position) + event.key + value.substring(position + 1);
      const transformedInput = this.timePipe.transform(value);
      this.control.control.setValue(transformedInput);
      this.element.nativeElement.setSelectionRange(position + 1, position + 1);
      event.preventDefault();
      event.stopPropagation();
    }
    // Delete and Backspace
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === ' ') {
      const dif = event.key === 'Backspace' ? -1 : 1;
      if (position === 0 && !positionDif) {
        this.control.control.patchValue('');
        this.element.nativeElement.setSelectionRange(position, position);
        event.preventDefault();
        event.stopPropagation();
      }
      if (
        (!positionDif && ((dif === 1 && (position === 5 || position === 2)) || (dif === -1 && (position === 6 || position === 3)))) ||
        (position >= 8 && dif === 1)
      ) {
        event.preventDefault();
        event.stopPropagation();
        this.element.nativeElement.setSelectionRange(position + dif, position + dif);
      } else if (event.target.value.length >= 3 && !positionDif) {
        if (dif === -1) {
          value = value.substring(0, position + dif) + '_' + value.substring(position || 0);
        } else {
          value = value.substring(0, position) + '_' + value.substring(position + dif || 0);
        }
        this.control.control.patchValue(value);
        this.element.nativeElement.setSelectionRange(position + dif, position + dif);
        event.preventDefault();
        event.stopPropagation();
      } else if (positionDif && positionDif !== value.length) {
        const arr = this.mask.substring(position, position + positionDif + 1);
        value = value.substring(0, position) + arr + value.substring(position + positionDif + 1 || 0);
        this.control.control.patchValue(value);
        this.element.nativeElement.setSelectionRange(position, position);
        event.preventDefault();
        event.stopPropagation();
      }
    }
    // ArrowUp and ArrowDown
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      let hours = value.substring(0, 2) || '12';
      let amPm = value.substring(6) || 'am';
      let minutes = value.substring(3, 5) || '00';
      const dif = event.key === 'ArrowUp' ? 1 : -1;
      if (position <= 2) {
        if (!isNaN(+hours)) {
          const { calcHours, calcAamPm } = this.calcHours(hours, dif, amPm);
          amPm = calcAamPm;
          hours = calcHours;
        } else {
          return;
        }
      } else if (position > 2 && position <= 5) {
        if (!isNaN(+minutes)) {
          const res = +minutes === 0 && dif < 1 ? 60 + dif * this.steps : +minutes + dif * this.steps;
          if (res === 60 || res === 0) {
            const { calcHours, calcAamPm } = this.calcHours(hours, dif, amPm);
            amPm = calcAamPm;
            hours = calcHours;
          }
          minutes = res === 0 || res >= 60 ? '00' : res + '';
        }
        minutes = minutes.length === 1 ? '0' + minutes : minutes;
      } else if (position > 5) {
        amPm = amPm === 'am' ? 'pm' : 'am';
      }
      const newValue = `${hours}:${minutes} ${amPm}`;
      this.control.control.patchValue(newValue);
      this.element.nativeElement.setSelectionRange(position, position);
      event.stopPropagation();
    }
  }

  calcHours(hours, dif, amPm) {
    let calcAamPm = amPm;
    let res = +hours + dif;
    if (res === 12 || (hours === '12' && dif < 0)) {
      calcAamPm = amPm === 'am' ? 'pm' : 'am';
    }
    if (res > 12) {
      res -= 12;
    } else if (res === 0) {
      res = 12;
    }
    const calcHours = res < 10 ? '0' + res : res.toString();
    return { calcHours, calcAamPm };
  }
}
