import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapper',
})
export class MapperPipe implements PipeTransform {
  transform(value: any, fieldName: string): any {
    return value.map(item => item[fieldName]);
  }
}
