import {
  Directive,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  HostListener,
  Sanitizer,
  SecurityContext,
} from '@angular/core';

@Directive({
  selector: '[appContenteditableDirective]',
})
export class ContenteditableDirective implements OnChanges {
  @Input() appContenteditableDirective: string;
  @Output() contenteditableModelChange? = new EventEmitter();
  @Input() contenteditableHtml? = false;

  constructor(
    private elRef: ElementRef,
    private sanitizer: Sanitizer,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appContenteditableDirective']) {
      if (changes['appContenteditableDirective'].isFirstChange() && !this.appContenteditableDirective) {
        this.onInput(true);
      }
      this.refreshView();
    }
  }

  @HostListener('input')
  @HostListener('blur')
  @HostListener('keyup')
  onInput(trim: boolean = false): void {
    let value = this.elRef.nativeElement[this.getProperty()];
    if (trim) {
      value = value.replace(/^[\n\s]+/, '');
      value = value.replace(/[\n\s]+$/, '');
    }
    this.contenteditableModelChange.emit(value);
  }

  @HostListener('paste') onPaste(): void {
    this.onInput();
    if (!this.contenteditableHtml) {
      setTimeout(() => {
        if (this.elRef.nativeElement.innerHTML !== this.elRef.nativeElement.innerText) {
          this.elRef.nativeElement.innerHTML = this.elRef.nativeElement.innerText;
        }
      });
    }
  }

  private refreshView(): void {
    const newContent: string = this.sanitize(this.appContenteditableDirective);
    if (newContent !== this.elRef.nativeElement[this.getProperty()]) {
      this.elRef.nativeElement[this.getProperty()] = newContent;
    }
  }

  private getProperty(): string {
    return this.contenteditableHtml ? 'innerHTML' : 'innerText';
  }

  private sanitize(content: string): string {
    return this.contenteditableHtml ? this.sanitizer.sanitize(SecurityContext.HTML, content) : content;
  }
}
