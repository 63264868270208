export interface DatatableColumnModel {
  name?: string;
  prop?: string;
  propSort?: string;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  headerClass?: string;
  cellClass?: string;
  pipe?: any;
  cellTemplate?: any;
  headerTemplate?: any;
  headerCheckboxable?: boolean;
  frozenLeft?: boolean;
  frozenRight?: boolean;
  resizeable?: boolean;
  sortable?: boolean;
  show?: boolean;
  draggable?: boolean;
  visible?: boolean;
  isOptionalColumn?: boolean;
  order?: number;
}

export interface DatatableSortEvent {
  column: DatatableColumnModel;
  newValue: 'asc' | 'desc';
  prevValue: 'asc' | 'desc';
  sorts: Array<{
    dir: 'asc' | 'desc';
    prop: string;
  }>;
}

export interface ResizeColumnEvent {
  column: DatatableColumnModel;
  newValue: number;
}

export enum NgxDatatableNamesEnum {
  // host/dashboard
  AdminDashboardEmployees = 'adminDashboardEmployees',
  // host/dashboard/documents-history/:id
  DocumentsHistory = 'documentsHistory',
  AttachmentsHistory = 'attachmentsHistory',
  DocumentsHistoryNotes = 'documentsHistoryNotes',
  // host/dashboard/employee-application/:id/
  AttachmentsNotEmployee = 'attachmentsNotEmployee',
  AttachmentsEmployee = 'attachmentsEmployee',
  // host/dashboard/patients
  PatientsMainTable = 'patientsMainTable',
  // host/dashboard/patient-docs/:id
  PatientAdmissions = 'patientAdmissions',
  PatientIncidents = 'patientIncidents',
  PatientAttachments = 'patientAttachments',
  PatientNotes = 'patientNotes',
  PatientTimeSheets = 'patientTimeSheets',
  PatientRedFlags = 'patientRedFlags',
  // host/dashboard/incidents
  IncidentsMainTable = 'incidentsMainTable',
  NewIncidentsTable = 'newIncidentsTable',
  AllIncidentsTable = 'allIncidentsTable',
  PatientsIncidentReport = 'patientsIncidentReport',
  // host/dashboard/incidents-attachments/:id
  IncidentsAttachments = 'incidentsAttachments',
  IncidentsNotes = 'incidentsNotes',
  // host/dashboard/admissions
  AdmissionMainTable = 'admissionMainTable',
  AdmissionByPatientMainTable = 'admissionByPatientMainTable',
  // host/dashboard/admission/:id
  AdmissionAttachmentTable = 'admissionAttachmentTable',
  // host/dashboard/admissions/admission-history/:id
  PatientsDocsAdmissionTable = 'patientsDocsAdmissionTable',
  // host/dashboard/hospitals
  HospitalMainTable = 'hospitalMainTable',
  // host/dashboard/doctors
  DoctorsMainTable = 'doctorsMainTable',
  // host/dashboard/timesheets
  TimeSheetMainTable = 'timeSheetMainTable',
  // host/dashboard/education-plan
  EducationPlanTable = 'educationPlanTable',
  // host/dashboard/education-plan-details/:id
  EducationPlanDetailsTable = 'educationPlanDetailsTable',
  // host/dashboard/education-category
  EducationCategoryTable = 'educationCategoryTable',
  // host/dashboard/user-management
  ManagementUsersTable = 'managementUsersTable',
  ManagementEmployeesTable = 'managementEmployeesTable',
  // host/dashboard/reports
  ReportAccuracy = 'reportAccuracy',
  ReportIncomplete = 'reportIncomplete',
  // host/dashboard/permissions
  PermissionsTable = 'permissionsTable',
  // host/dashboard/red-flags
  RedFlagsMainTable = 'redFlagsMainTable',
  PatientsRedFlagsMainTable = 'patientsRedFlagsMainTable',
  RedFlagsAttachmentTable = 'redFlagsAttachmentTable',
  // host/dashboard/medical
  MedicalDashboardEmployees = 'medicalDashboardEmployees',
  AttachmentsMedicalNotEmployee = 'attachmentsMedicalNotEmployee',
  AttachmentsMedicalEmployee = 'attachmentsMedicalEmployee',
  MedicalDocumentsHistory = 'medicalDocumentsHistory',
  MedicalAttachmentsHistory = 'medicalAttachmentsHistory',
  MedicalDocumentsHistoryNotes = 'medicalDocumentsHistoryNotes',
  // host/dashboard/reports/mandatory-attachments
  MandatoryAttachments = 'mandatoryAttachments',
  TimeSheetsDailyReport = 'timeSheetsDailyReport',
}

export enum NgxDatatableColumnSizes {
  MaxWidth = 10000,
  MinWidth = 160,
  With180 = 180,
  EmptyColumnWidth = 50,
  EmptyColumnWidthLG = 250,
  OneBtnWidth = 60,
  TwoBtnWidth = 85,
  TwoBtnWidthBig = 95,
  ThreeBtnWidth = 125,
  FourBtnWidth = 170,
  FiveBtnWidth = 190,
  SixBtnWidth = 220,
}

export const EMPTY_COLUMN = {
  name: '',
  width: NgxDatatableColumnSizes.EmptyColumnWidth,
  minWidth: NgxDatatableColumnSizes.EmptyColumnWidth,
  maxWidth: NgxDatatableColumnSizes.EmptyColumnWidth,
  headerClass: 'cursor-initial',
  draggable: false,
  sortable: false,
  resizeable: false,
  visible: true,
  isOptionalColumn: false,
};
export const EMPTY_COLUMN_LG = {
  name: '',
  width: NgxDatatableColumnSizes.EmptyColumnWidthLG,
  minWidth: NgxDatatableColumnSizes.EmptyColumnWidthLG,
  maxWidth: NgxDatatableColumnSizes.EmptyColumnWidthLG,
  headerClass: 'cursor-initial',
  draggable: false,
  sortable: false,
  resizeable: false,
  visible: true,
  isOptionalColumn: false,
};

export type NgxDatatableNamesType =
  | 'adminDashboardEmployees'
  | 'documentsHistory'
  | 'attachmentsHistory'
  | 'documentsHistoryNotes'
  | 'attachmentsNotEmployee'
  | 'attachmentsEmployee'
  | 'patientsMainTable'
  | 'patientAdmissions'
  | 'patientIncidents'
  | 'patientAttachments'
  | 'patientNotes'
  | 'patientTimeSheets'
  | 'patientRedFlags'
  | 'incidentsMainTable'
  | 'newIncidentsTable'
  | 'allIncidentsTable'
  | 'patientsIncidentReport'
  | 'incidentsAttachments'
  | 'incidentsNotes'
  | 'admissionMainTable'
  | 'admissionByPatientMainTable'
  | 'admissionAttachmentTable'
  | 'patientsDocsAdmissionTable'
  | 'hospitalMainTable'
  | 'doctorsMainTable'
  | 'timeSheetMainTable'
  | 'educationPlanTable'
  | 'educationPlanDetailsTable'
  | 'educationCategoryTable'
  | 'managementUsersTable'
  | 'managementEmployeesTable'
  | 'reportAccuracy'
  | 'reportIncomplete'
  | 'permissionsTable'
  | 'redFlagsMainTable'
  | 'patientsRedFlagsMainTable'
  | 'redFlagsAttachmentTable'
  | 'medicalDashboardEmployees'
  | 'attachmentsMedicalNotEmployee'
  | 'attachmentsMedicalEmployee'
  | 'medicalDocumentsHistory'
  | 'medicalAttachmentsHistory'
  | 'medicalDocumentsHistoryNotes'
  | 'mandatoryAttachments'
  | 'timeSheetsDailyReport';
